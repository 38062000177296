import React from 'react'
import { WhyJostiBlock, WhyJostiBlockNumber, WhyJostiBlockText, WhyJostiBlockTitle, WhyJostiDescription, WhyJostiDescriptionArrow, WhyJostiDescriptionText, WhyJostiWrapper } from './index.styled'

const WhyJosti = () => {
	return (
		<WhyJostiWrapper>
			<WhyJostiBlock>
				<WhyJostiBlockNumber>1.</WhyJostiBlockNumber>
				<WhyJostiBlockTitle>Вигода</WhyJostiBlockTitle>
				<WhyJostiBlockText>Тепер вам не потрібно сплачувати комісію при заселенні від 50%.</WhyJostiBlockText>
				<WhyJostiDescription>
					<WhyJostiDescriptionArrow>
						<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M9.35509 0.0290394C5.93708 0.394987 2.71768 2.62727 1.12888 5.74306C0.329257 7.30095 0 8.70724 0 10.5265C0.00522631 13.3548 0.998225 15.8014 2.979 17.8403C4.69845 19.6177 6.86214 20.6737 9.34986 20.9613C10.0554 21.0397 11.6756 20.9926 12.308 20.8776C14.0274 20.5587 15.5796 19.8739 16.9594 18.8179C17.3879 18.4937 18.2921 17.6102 18.6579 17.1659C21.6839 13.4593 21.7885 8.06944 18.9088 4.15902C18.4907 3.59442 17.597 2.68478 17.0221 2.23518C15.5221 1.06938 13.7295 0.311342 11.8794 0.0708618C11.3359 -0.00232697 9.85682 -0.0232391 9.35509 0.0290394ZM12.355 6.86704C12.4491 6.91409 13.233 7.66167 14.0954 8.52949C15.4542 9.89395 15.6737 10.1344 15.7051 10.3017C15.7887 10.7513 15.773 10.7722 14.2992 12.2988C12.7052 13.9455 12.4909 14.1442 12.3132 14.1912C11.848 14.3062 11.3411 13.888 11.3411 13.3861C11.3411 13.1195 11.477 12.9418 12.3289 12.0949L13.0135 11.4153L9.4753 11.3891C5.4824 11.3577 5.69667 11.3839 5.41445 10.9552C5.10088 10.4638 5.35696 9.83122 5.94754 9.66393C6.07297 9.63256 7.36909 9.61165 9.56414 9.61165H12.9874L12.193 8.81179C11.3202 7.93874 11.1947 7.75054 11.2888 7.42642C11.3568 7.19116 11.477 7.03956 11.7122 6.89841C11.9212 6.76771 12.125 6.75725 12.355 6.86704Z"
								fill="#8363FF"
							/>
						</svg>
					</WhyJostiDescriptionArrow>
					<WhyJostiDescriptionText>Забронювавши навіть 5 переглядів ви зекономите від 30% на довгостроковій оренді.</WhyJostiDescriptionText>
				</WhyJostiDescription>
			</WhyJostiBlock>
			<WhyJostiBlock>
				<WhyJostiBlockNumber>2.</WhyJostiBlockNumber>
				<WhyJostiBlockTitle>Час</WhyJostiBlockTitle>
				<WhyJostiBlockText>Тепер ви можете забути про неактуальні обєкти та “заманухи” від ріелторів та не витрачати свій час на них.</WhyJostiBlockText>
				{/*<WhyJostiBlockText>Все зроби у додатку:</WhyJostiBlockText>*/}
				{/*<WhyJostiDescription>*/}
				{/*	<WhyJostiDescriptionArrow>*/}
				{/*		<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
				{/*			<path*/}
				{/*				d="M9.35509 0.0290394C5.93708 0.394987 2.71768 2.62727 1.12888 5.74306C0.329257 7.30095 0 8.70724 0 10.5265C0.00522631 13.3548 0.998225 15.8014 2.979 17.8403C4.69845 19.6177 6.86214 20.6737 9.34986 20.9613C10.0554 21.0397 11.6756 20.9926 12.308 20.8776C14.0274 20.5587 15.5796 19.8739 16.9594 18.8179C17.3879 18.4937 18.2921 17.6102 18.6579 17.1659C21.6839 13.4593 21.7885 8.06944 18.9088 4.15902C18.4907 3.59442 17.597 2.68478 17.0221 2.23518C15.5221 1.06938 13.7295 0.311342 11.8794 0.0708618C11.3359 -0.00232697 9.85682 -0.0232391 9.35509 0.0290394ZM12.355 6.86704C12.4491 6.91409 13.233 7.66167 14.0954 8.52949C15.4542 9.89395 15.6737 10.1344 15.7051 10.3017C15.7887 10.7513 15.773 10.7722 14.2992 12.2988C12.7052 13.9455 12.4909 14.1442 12.3132 14.1912C11.848 14.3062 11.3411 13.888 11.3411 13.3861C11.3411 13.1195 11.477 12.9418 12.3289 12.0949L13.0135 11.4153L9.4753 11.3891C5.4824 11.3577 5.69667 11.3839 5.41445 10.9552C5.10088 10.4638 5.35696 9.83122 5.94754 9.66393C6.07297 9.63256 7.36909 9.61165 9.56414 9.61165H12.9874L12.193 8.81179C11.3202 7.93874 11.1947 7.75054 11.2888 7.42642C11.3568 7.19116 11.477 7.03956 11.7122 6.89841C11.9212 6.76771 12.125 6.75725 12.355 6.86704Z"*/}
				{/*				fill="#8363FF"*/}
				{/*			/>*/}
				{/*		</svg>*/}
				{/*	</WhyJostiDescriptionArrow>*/}
				{/*	<WhyJostiDescriptionText>зайди - вибери - забронюй - заїдь</WhyJostiDescriptionText>*/}
				{/*</WhyJostiDescription>*/}
			</WhyJostiBlock>
			<WhyJostiBlock>
				<WhyJostiBlockNumber>3.</WhyJostiBlockNumber>
				<WhyJostiBlockTitle>Актуальність</WhyJostiBlockTitle>
				<WhyJostiBlockText>Додаток Josti постійно перевіряє обєкти і відповідає за їх актуальність.</WhyJostiBlockText>
				<WhyJostiDescription>
					<WhyJostiDescriptionArrow>
						<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M9.35509 0.0290394C5.93708 0.394987 2.71768 2.62727 1.12888 5.74306C0.329257 7.30095 0 8.70724 0 10.5265C0.00522631 13.3548 0.998225 15.8014 2.979 17.8403C4.69845 19.6177 6.86214 20.6737 9.34986 20.9613C10.0554 21.0397 11.6756 20.9926 12.308 20.8776C14.0274 20.5587 15.5796 19.8739 16.9594 18.8179C17.3879 18.4937 18.2921 17.6102 18.6579 17.1659C21.6839 13.4593 21.7885 8.06944 18.9088 4.15902C18.4907 3.59442 17.597 2.68478 17.0221 2.23518C15.5221 1.06938 13.7295 0.311342 11.8794 0.0708618C11.3359 -0.00232697 9.85682 -0.0232391 9.35509 0.0290394ZM12.355 6.86704C12.4491 6.91409 13.233 7.66167 14.0954 8.52949C15.4542 9.89395 15.6737 10.1344 15.7051 10.3017C15.7887 10.7513 15.773 10.7722 14.2992 12.2988C12.7052 13.9455 12.4909 14.1442 12.3132 14.1912C11.848 14.3062 11.3411 13.888 11.3411 13.3861C11.3411 13.1195 11.477 12.9418 12.3289 12.0949L13.0135 11.4153L9.4753 11.3891C5.4824 11.3577 5.69667 11.3839 5.41445 10.9552C5.10088 10.4638 5.35696 9.83122 5.94754 9.66393C6.07297 9.63256 7.36909 9.61165 9.56414 9.61165H12.9874L12.193 8.81179C11.3202 7.93874 11.1947 7.75054 11.2888 7.42642C11.3568 7.19116 11.477 7.03956 11.7122 6.89841C11.9212 6.76771 12.125 6.75725 12.355 6.86704Z"
								fill="#8363FF"
							/>
						</svg>
					</WhyJostiDescriptionArrow>
					<WhyJostiDescriptionText>Тому якщо житло виявиться не актуальним, кошти вам повернуться.</WhyJostiDescriptionText>
				</WhyJostiDescription>
			</WhyJostiBlock>
		</WhyJostiWrapper>
	)
}

export default WhyJosti
