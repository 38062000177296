import React, { useEffect } from 'react'
import { QrCodeBlock, QrCodeModalBody, QrCodeModalCross, QrCodeModalWrapper, QrCodeText } from './index.styled'
import cross from '../../img/modal/big-cross.png'
import qrcode from '../../img/modal/qr-code.png'
import { useWindowBreakPoint } from '../../hooks/useWindowBreakPoint'
import { theme } from '../../style/theme'

const QrCodeModal = ({ qrIsOpen, setQrIsOpen }) => {
	const layout = qrIsOpen ? 'active' : ''
	const isSmallTablet = useWindowBreakPoint(theme.point720)

	useEffect(() => {
		setTimeout(() => {
			setQrIsOpen(true)
		}, 2000)
	}, [])

	const handleClose = () => {
		setQrIsOpen(false)
	}

	const handleModalClick = e => {
		e.stopPropagation()
	}

	return (
		!isSmallTablet && (
			<QrCodeModalWrapper className={layout} onClick={handleClose}>
				<QrCodeModalBody className={layout} onClick={handleModalClick}>
					<QrCodeModalCross onClick={handleClose}>
						<img src={cross} alt="x" />
					</QrCodeModalCross>
					<QrCodeBlock>
						<img src={qrcode} alt="QrCode" />
					</QrCodeBlock>
					<QrCodeText>Відскануй QR код та починай користуватись Josti</QrCodeText>
				</QrCodeModalBody>
			</QrCodeModalWrapper>
		)
	)
}

export default QrCodeModal
