import React, { useState } from 'react'
import { FaqItemAnswer, FaqItemText, FaqItemWrapper, FaqPlus, FaqText } from '../../index.styled'
import plus from '../../../../../../img/faq/plus.png'
import minus from '../../../../../../img/faq/minus.png'

const FaqItem = ({ text, answer }) => {
	const [isOpen, setIsOpen] = useState(false)

	const layout = isOpen ? 'active' : ''

	const handleClick = () => {
		setIsOpen(!isOpen)
	}

	return (
		<FaqItemWrapper onClick={handleClick}>
			<FaqItemText>
				<FaqText>{text}</FaqText>
				<FaqPlus src={isOpen ? minus : plus} />
			</FaqItemText>
			<FaqItemAnswer className={layout}>{answer}</FaqItemAnswer>
		</FaqItemWrapper>
	)
}

export default FaqItem
