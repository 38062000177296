import styled from 'styled-components'
import { theme } from '../../../../style/theme'

export const BookingStepsWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 15px;

	@media screen and (max-width: ${theme.point720}) {
		grid-template-columns: 1fr;
	}
`

export const BookingStep = styled.div`
	border-radius: 20px;
	background: #ffffff;
	padding: 15px;
	overflow: hidden;

	&.step3 {
		display: flex;
		gap: 30px;
		align-items: flex-start;
		grid-column: 1 / 3;
	}

	@media screen and (max-width: ${theme.point720}) {
		&.step3 {
			grid-column: auto;
			display: block;
		}
	}

	@media screen and (max-width: ${theme.point520}) {
		min-height: 507px;
	}
`

export const BookingStepImages = styled.div`
	border-radius: 20px;
	background: #8363ff;
	width: 100%;
	height: 250px;
	position: relative;
	padding: 20px 16px;

	&.step2 {
		padding-bottom: 0;
	}

	&.step3 {
		padding-top: 15px;
		padding-bottom: 15px;
	}

	@media screen and (max-width: ${theme.point1024}) {
		height: 200px;
		padding: 10px;

		&.step2 {
			padding-bottom: 0;
		}

		&.step3 {
			flex-shrink: 0;
			width: 47%;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		&.step3 {
			width: 100%;
		}
	}

	@media screen and (max-width: ${theme.point520}) {
		height: 175px;
	}
`

export const BookingStepNumber = styled.div`
	position: absolute;
	top: 15px;
	left: 15px;
	height: 38px;
	padding: 5px 15px;
	border-radius: 12px;
	background: #f5f4f9;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: #282828;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	width: fit-content;

	&.step3 {
		position: static;
	}

	@media screen and (max-width: ${theme.point1024}) {
		left: 15px;
		top: 15px;
	}

	@media screen and (max-width: ${theme.point720}) {
		&.step3 {
			position: absolute;
		}
	}
`

export const BookingStepImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: contain;
`

export const BookingStepInfo = styled.div``

export const BookingStepTitle = styled.div`
	margin-top: 25px;
	color: #282828;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;

	@media screen and (max-width: ${theme.point1024}) {
		margin-top: 25px;
	}
`

export const BookingStepText = styled.div`
	margin-top: 15px;
	color: #282828;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
`
