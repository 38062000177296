import React, { useEffect } from 'react'
import Header from '../../component/Header'
import Footer from '../../component/Footer'
import { PageContainer, PageWrapper } from '../LandingPage/index.styled'

const TestPage = () => {
	useEffect(() => {
		window.open('https://onelink.to/jffm9g')
	}, [])

	return (
		<PageWrapper>
			<PageContainer>
				<Header />
			</PageContainer>
			<Footer />
		</PageWrapper>
	)
}

export default TestPage
