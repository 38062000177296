import React from 'react'
import { Route, Routes } from 'react-router-dom'
import LandingPage from '../pages/LandingPage'
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage'
import TermsOfUsePage from '../pages/TermsOfUsePage'
import TestPage from '../pages/TestPage'

const Main = React.memo(() => {
	return (
		<Routes>
			<Route path="/*" element={<LandingPage />} />
			{/*<Route path="/host" element={<HostPage />} />*/}
			<Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
			<Route path="/terms-of-use" element={<TermsOfUsePage />} />
			<Route path="/test" element={<TestPage />} />

			{/*<Route element={<PrivatePage component={() => <h1>404</h1>}/>}/>*/}
		</Routes>
	)
})

Main.displayName = 'Main'
export default Main
