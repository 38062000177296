import React, { useState } from 'react'
import { LandingHeading, PageContainer, PageWrapper } from './index.styled'
import Header from '../../component/Header'
import RentLongTerm from './components/RentLongTerm'
import JostiDefinitions from './components/JostiDefinitions'
import StartEarning from './components/StartEarning'
import WhyJosti from './components/WhyJosti'
import Footer from '../../component/Footer'
import LineModal from '../../component/LineModal'
import BookingSteps from './components/BookingSteps'
import Actual from './components/Actual'
import JostiApp from './components/JostiApp'
import JostiFaq from './components/JostiFAQ'
import QrCodeModal from '../../component/QrCodeModal'
import { useWindowBreakPoint } from '../../hooks/useWindowBreakPoint'
import { theme } from '../../style/theme'

const LandingPage = () => {
	const [qrIsOpen, setQrIsOpen] = useState(false)
	const isMobile = useWindowBreakPoint(theme.point520)

	return (
		<PageWrapper>
			{isMobile && <RentLongTerm />}
			<PageContainer>
				<Header />
				{!isMobile && <RentLongTerm />}
				<LandingHeading id="about-us">Josti - це:</LandingHeading>
				<JostiDefinitions />
				<LandingHeading id="host">Почни заробляти разом з нами!</LandingHeading>
				<StartEarning />
				<LandingHeading>Чому Josti?</LandingHeading>
				<WhyJosti />
				<LandingHeading id="booking">Як забронювати житло у додатку</LandingHeading>
				<BookingSteps />
				<LandingHeading>Актуальні оголошення</LandingHeading>
				<Actual {...{ setQrIsOpen }} />
				<JostiApp {...{ setQrIsOpen }} />
				<JostiFaq />
			</PageContainer>
			<Footer />
			<LineModal />
			<QrCodeModal {...{ qrIsOpen, setQrIsOpen }} />
		</PageWrapper>
	)
}

export default LandingPage
