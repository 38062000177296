import React, { useState } from 'react'
import { HeaderBody, HeaderButtons, HeaderLogo, HeaderNav, HeaderSupportIcon, HeaderWrapper, RentButton, WriteUsButton } from './index.styled'
import HeaderNavLink from './components/HeaderNavLink'
import { useWindowBreakPoint } from '../../hooks/useWindowBreakPoint'
import { theme } from '../../style/theme'
import HeaderBurger from './components/HeaderBurger'
import MobileHeader from './components/MobileHeader'

const Header = () => {
	const isTablet = useWindowBreakPoint(theme.point1024)
	const isSmallTablet = useWindowBreakPoint(theme.point720)
	const [mobileHeaderIsOpen, setMobileHeaderIsOpen] = useState(false)

	const handleNavigateHome = () => {
		window.open('/', '_self')
	}

	const handleNavigateToHost = () => {
		// window.open('https://send.monobank.ua/jar/4DMHzHxyk', '_blank')
		window.open('https://host.josti.com.ua', '_blank')
	}

	const handleNavigateToContact = () => {
		window.open('https://t.me/josticom', '_blank')
	}

	const handleBurgerClick = () => {
		setMobileHeaderIsOpen(!mobileHeaderIsOpen)
	}

	const layout = mobileHeaderIsOpen ? 'mobile-header-active' : ''

	return (
		<>
			<HeaderWrapper className={layout}>
				<HeaderBody className={layout}>
					<HeaderLogo onClick={handleNavigateHome}>
						<svg viewBox="0 0 79 35" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M23.0562 10.0345C19.6421 10.8808 17.1521 13.4196 16.305 16.9138C15.9713 18.3197 15.9713 20.6128 16.305 22.0187C16.9211 24.5711 18.5254 26.7687 20.6817 27.9835C23.3386 29.4985 27.356 29.403 29.8973 27.7651C32.5927 26.0179 34.0431 23.4519 34.2356 20.0122C34.4923 15.5216 32.028 11.6724 28.0234 10.2802C26.7912 9.85707 24.2756 9.73423 23.0562 10.0345ZM26.5345 15.972C27.741 16.5589 28.4726 18.115 28.3443 19.8211C28.2544 20.8994 28.0362 21.5 27.4972 22.1688C26.8939 22.8786 26.1623 23.2198 25.1612 23.2198C23.1332 23.2198 21.9267 21.8139 21.9267 19.4662C21.9267 17.801 22.4786 16.6818 23.6081 16.0539C24.3782 15.6308 25.7388 15.5898 26.5345 15.972Z"
								fill="#724FFF"
							/>
							<path
								d="M24.2352 0.163616C23.5823 0.43633 11.864 9.58132 11.564 10.054C10.8934 11.1085 11.0698 12.6175 12.0052 13.5266C12.6052 14.1265 13.9818 14.3447 14.67 13.9811C14.8995 13.8538 17.2996 12.0176 20.0174 9.92676C22.7352 7.81778 25.047 6.10877 25.1529 6.10877C25.2588 6.10877 27.5707 7.81778 30.2885 9.92676C33.0063 12.0176 35.4064 13.8538 35.6358 13.9811C36.3241 14.3447 37.7006 14.1265 38.3007 13.5266C39.236 12.6175 39.4125 11.1085 38.7419 10.054C38.4242 9.54496 26.6883 0.418149 26.0177 0.163616C25.4706 -0.054554 24.7647 -0.054554 24.2352 0.163616Z"
								fill="#724FFF"
							/>
							<path d="M72.4805 19.4634V28.7158H75.5587H78.637V19.4634V10.211H75.5587H72.4805V19.4634Z" fill="#724FFF" />
							<path
								d="M3.51282 10.4816C3.36796 10.5973 3.25932 14.8379 3.25932 19.8882C3.25932 28.5624 3.2231 29.1021 2.57124 29.4876C2.17288 29.6804 1.44859 29.8731 0.941581 29.8731C0.0362147 29.8731 0 29.9502 0 32.4175V35.0005L3.00582 34.8077C5.35977 34.6921 6.26514 34.4608 7.13429 33.8054C9.19852 32.2248 9.41581 30.9526 9.41581 20.0424V10.2117H6.59107C5.03384 10.2117 3.62147 10.3274 3.51282 10.4816Z"
								fill="#724FFF"
							/>
							<path
								d="M43.3995 10.6372C41.5526 11.6781 40.611 13.413 40.611 15.7646C40.611 19.1957 42.7839 21.3546 46.7675 21.9329C48.7955 22.2413 49.4836 22.6654 49.1939 23.475C48.8317 24.4388 46.4054 24.3231 46.1519 23.3208C45.9708 22.6268 45.6448 22.5497 42.9288 22.5497H39.8867L40.104 23.5906C40.9732 27.4843 43.2547 29.1035 47.8539 29.1035C51.0408 29.1035 52.7429 28.4481 54.3002 26.6748C55.8212 24.9399 56.0747 21.6245 54.8072 19.5427C54.0829 18.3476 52.8154 17.6922 50.0268 17.1525C46.9486 16.4971 46.2243 16.1116 46.8037 15.3791C47.4194 14.6081 48.5058 14.7237 49.0128 15.6104C49.4112 16.2658 49.882 16.3815 52.2721 16.3815H55.0969V15.2635C55.0969 13.7599 53.7207 11.7552 52.0186 10.7144C50.063 9.51925 45.4638 9.4807 43.3995 10.6372Z"
								fill="#724FFF"
							/>
							<path
								d="M59.8062 7.70714V10.213H58.1765H56.5469V13.1044V15.9958H58.1765H59.8062V20.1208C59.8062 26.9444 60.9651 28.448 66.5421 28.6407L69.9463 28.7949V26.0578V23.3206H68.1356C65.8903 23.3206 65.6005 22.8194 65.6005 18.9257V15.9958H67.7734H69.9463V13.1044V10.213H67.7734H65.6005V7.70714V5.20128H62.7034H59.8062V7.70714Z"
								fill="#724FFF"
							/>
							<path d="M73.0245 3.07993C72.4089 3.69675 72.1191 4.42923 72.1191 5.39303C72.1191 7.43627 73.4229 8.66992 75.5595 8.66992C77.6962 8.66992 78.9999 7.43627 78.9999 5.39303C78.9999 3.34979 77.6962 2.11613 75.5595 2.11613C74.292 2.11613 73.6764 2.34744 73.0245 3.07993Z" fill="#724FFF" />
							<path d="M4.12729 3.03953C3.36678 3.73346 3.11328 4.27318 3.11328 5.39118C3.11328 9.32345 9.0887 9.97883 9.99407 6.16221C10.7546 2.92387 6.58989 0.726424 4.12729 3.03953Z" fill="#724FFF" />
						</svg>
					</HeaderLogo>
					<HeaderNav>
						<HeaderNavLink
							text="Наш сервіс"
							subItems={[
								{ text: 'Про нас', elementId: 'about-us' },
								{ text: 'Як забронювати', elementId: 'booking' },
								{ text: 'Часті запитання', elementId: 'faq' }
							]}
						/>
						<HeaderNavLink text="Співпрацюйте з Josti" subItems={[{ text: 'Зареєструватись власником', elementId: 'host' }]} />
					</HeaderNav>
					<HeaderButtons>
						{!isTablet && <WriteUsButton onClick={handleNavigateToContact}>Написати нам</WriteUsButton>}
						{isTablet && (
							<HeaderSupportIcon>
								<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" onClick={handleNavigateToContact}>
									<g clipPath="url(#clip0_129_1940)">
										<path
											d="M10.1406 0.562805C7.81172 0.721788 5.68047 1.52101 3.80273 2.93468C3.36016 3.26984 2.28164 4.34835 1.94648 4.79093C0.971094 6.08429 0.391016 7.40343 0.103125 8.9589C0.00859375 9.46163 -0.0257813 10.9398 0.0386719 11.4554C0.223438 12.8991 0.721875 14.2827 1.46094 15.4214L1.72734 15.8296L1.67578 16.255C1.43945 18.3089 0.923828 19.8429 0.184766 20.6894C0.0300781 20.8655 0 20.9343 0 21.0804C0 21.2651 0.103125 21.4026 0.283594 21.4585C0.502734 21.523 2.63398 21.0417 3.80703 20.6636C4.64062 20.3929 5.60742 20.0019 6.12305 19.7269L6.51406 19.5206L6.85352 19.6452C8.09531 20.105 9.60781 20.3671 11 20.3671C16.6332 20.3671 21.3898 16.5128 21.9613 11.4812C22.0258 10.8925 21.9914 9.47023 21.8969 8.9589C21.609 7.39913 21.0332 6.08859 20.0535 4.79093C19.7184 4.34835 18.6398 3.26984 18.1973 2.93468C16.2852 1.49523 14.2055 0.726086 11.7691 0.558508C11.0816 0.511242 10.8582 0.511242 10.1406 0.562805ZM6.37227 9.10929C6.91797 9.31554 7.30469 9.87843 7.30469 10.4757C7.30469 11.305 6.44102 12.0269 5.6375 11.8636C5.30664 11.7991 5.05312 11.6573 4.82969 11.421C4.58047 11.1546 4.46016 10.8796 4.43867 10.5273C4.3957 9.9171 4.80391 9.30694 5.40547 9.0878C5.65039 8.99757 6.10156 9.01046 6.37227 9.10929ZM11.623 9.16085C12.4953 9.61202 12.6973 10.7464 12.027 11.4339C11.7305 11.739 11.4125 11.8765 11 11.8765C10.7422 11.8765 10.6262 11.8507 10.4285 11.7605C9.69805 11.4253 9.36719 10.5745 9.70234 9.86983C9.88711 9.47882 10.2051 9.19523 10.6133 9.06202C10.6734 9.04054 10.8754 9.03195 11.0645 9.04054C11.3309 9.04913 11.4555 9.07491 11.623 9.16085ZM16.7191 9.14366C17.0371 9.29835 17.2563 9.51749 17.4109 9.83116C17.5355 10.0847 17.5527 10.1577 17.5527 10.4628C17.5484 10.8796 17.4582 11.1159 17.1703 11.421C16.5859 12.0441 15.6836 12.0398 15.0863 11.4081C14.7426 11.0472 14.6137 10.5359 14.7469 10.0804C14.8715 9.65069 15.2152 9.26827 15.6148 9.10929C15.9156 8.98898 16.4398 9.00616 16.7191 9.14366Z"
											fill="#724FFF"
										/>
									</g>
									<defs>
										<clipPath id="clip0_129_1940">
											<rect width="22" height="22" fill="white" />
										</clipPath>
									</defs>
								</svg>
							</HeaderSupportIcon>
						)}
						<RentButton onClick={handleNavigateToHost}>Здати житло</RentButton>
						{isSmallTablet && <HeaderBurger isOpen={mobileHeaderIsOpen} onClick={handleBurgerClick} />}
					</HeaderButtons>
				</HeaderBody>
			</HeaderWrapper>
			<MobileHeader isOpen={mobileHeaderIsOpen} setIsOpen={setMobileHeaderIsOpen} />
		</>
	)
}

export default Header
