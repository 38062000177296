import React from 'react'
import Header from '../../component/Header'
import Footer from '../../component/Footer'
import { LandingHeading, PageContainer, PageWrapper } from '../LandingPage/index.styled'

const TermsOfUsePage = () => {
	return (
		<PageWrapper>
			<PageContainer>
				<Header />
				<LandingHeading className="full-width">Умови користування для додатка Josti:</LandingHeading>
				<h2>Предмет.</h2>
				<ul>
					<li>1.1. Додаток Josti надає користувачам можливість шукати та орендувати житло на довгостроковий термін без комісії.</li>
					<li>1.2. Наша компанія автоматизує процес збору та відображення об&apos;єктів нерухомості з різних джерел для зручності наших користувачів.</li>
				</ul>
				<h2>Платіж за доступ до контактів.</h2>
				<ul>
					<li>2.1. Для отримання контактів власників об&apos;єктів нерухомості, користувачам додатка необхідно здійснити плату, розмір якої становить 4% від вартості оренди квартири.</li>
					<li>2.2. Після успішної оплати користувач отримує доступ до контактних даних власника об&apos;єкта.</li>
				</ul>
				<h2>Відповідальність за актуальність об&apos;єктів.</h2>
				<ul>
					<li>3.1. Команда Josti забезпечує моніторинг та оновлення інформації про доступні об&apos;єкти нерухомості.</li>
					<li>3.2. У випадку, якщо об&apos;єкт виявиться неактуальним або недоступним, кошти за доступ до контактів будуть повернуті на онлайн баланс користувача в додатку Josti.</li>
				</ul>
				<h2>Повернення коштів.</h2>
				<ul>
					<li>4.1. Сума, сплачена за доступ до контактів, повертається на онлайн баланс у додатку Josti.</li>
					<li>4.2. Повернення коштів здійснюється у випадках, коли об&apos;єкт виявився неактуальним або недоступним.</li>
				</ul>
				<h2>Конфіденційність.</h2>
				<ul>
					<li>5.1. Додаток Josti забезпечує конфіденційність особистої інформації своїх користувачів та не передає її третім особам.</li>
					<li>5.2. Компанія Josti додержується всіх вимог щодо захисту персональних даних і не розголошує їх без згоди користувачів.</li>
				</ul>
				<h2>Права і обов&apos;язки користувачів.</h2>
				<ul>
					<li>6.1. Користувачі зобов&apos;язані використовувати додаток відповідно до його призначення і умов користування.</li>
					<li>
						6.2. Користувачі мають право отримувати актуальну інформацію про об&apos;єкти нерухомості та повертати кошти у випадку неактуальності об&apos;єкта. Будь ласка, уважно прочитайте ці умови перед використанням додатка Josti. Натиснувши «Бронювати перегляд» ви автоматично погоджуєтесь із
						даними умовами користування. Якщо у вас виникли питання або зауваження, будь ласка, зверніться до нашої служби підтримки. Дякуємо за користування додатком Josti! Якщо у Вас виникли запитання щодо цієї Угоди, надішліть нам листа на support@josti.com.ua
					</li>
				</ul>
				<p>Останнє оновлення: 25 березня 2023 р.</p>
			</PageContainer>
			<Footer />
		</PageWrapper>
	)
}

export default TermsOfUsePage
