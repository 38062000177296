import React from 'react'
import { AppButton, AppButtonIcon, AppButtons, AppButtonText, AppLocation, AppLocationItems, AppLocationText, LocationItem, LocationItemIcon, LocationItemText, LongTermBlock, LongTermHeading, RentLongTermImg, RentLongTermWrapper } from './index.styled'
import background from '../../../../img/long-rent/background.webp'

const RentLongTerm = () => {
	const handleAppStoreClick = () => {
		window.open('https://apps.apple.com/ua/app/josti-%D0%B4%D0%BE%D0%B2%D0%B3%D0%BE%D1%81%D1%82%D1%80%D0%BE%D0%BA%D0%BE%D0%B2%D0%B0-%D0%BE%D1%80%D0%B5%D0%BD%D0%B4%D0%B0/id6446787293', '_blank')
	}

	const handleGooglePlayClick = () => {
		window.open('https://play.google.com/store/apps/details?id=com.gmnapp&pcampaignid=web_share', '_blank')
	}

	return (
		<RentLongTermWrapper>
			<RentLongTermImg src={background} />
			<LongTermBlock>
				<LongTermHeading>
					Орендуй
					<br /> довгостроково! <b>Без комісії</b>
				</LongTermHeading>
				<AppButtons>
					<AppButton onClick={handleAppStoreClick}>
						<AppButtonIcon>
							<svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M14.503 0.0816407C13.7295 0.275 12.9948 0.657422 12.3373 1.21602C11.233 2.15273 10.5284 3.70391 10.6358 4.96289L10.6659 5.32812L10.9924 5.29805C11.8604 5.2293 12.6811 4.87695 13.4459 4.24102C14.142 3.66953 14.7737 2.64258 15.0444 1.6543C15.1131 1.40078 15.1432 1.13867 15.1561 0.653125L15.1776 0L14.9928 0.00429678C14.8897 0.00429678 14.6705 0.038672 14.503 0.0816407Z"
									fill="white"
								/>
								<path
									d="M6.76765 5.16055C4.72234 5.33672 2.66843 7.06836 1.97664 9.19961C1.52976 10.5832 1.46961 12.3062 1.80906 14.0508C2.12273 15.6449 2.72429 17.1832 3.61375 18.6484C4.55906 20.2039 5.52586 21.2609 6.4239 21.7164C6.845 21.927 7.16297 22 7.67 22C8.18562 22 8.50359 21.9227 9.20398 21.6219C10.1149 21.2309 10.6778 21.0977 11.4298 21.0977C12.1516 21.0977 12.6501 21.218 13.5309 21.6004C14.2657 21.9184 14.5794 22 15.0864 22C15.5677 22 15.9329 21.9055 16.3712 21.6691C17.4626 21.0762 18.8934 19.2672 19.9333 17.166C20.1696 16.6934 20.3716 16.268 20.3845 16.2207C20.4059 16.1477 20.3458 16.1004 19.9634 15.9113C18.7903 15.3141 17.9782 14.3645 17.5571 13.1055C17.3552 12.4953 17.2993 12.0656 17.3294 11.3266C17.3552 10.6262 17.4712 10.1406 17.7419 9.56055C18.1157 8.76133 18.7473 8.04375 19.4735 7.59258C19.6325 7.49375 19.7657 7.39492 19.7657 7.37773C19.7657 7.31328 19.3145 6.82773 18.9923 6.54844C18.395 6.02851 17.643 5.58164 17.0157 5.37969C16.4872 5.20781 16.1134 5.14766 15.5118 5.14766C14.661 5.14766 14.154 5.25508 12.9552 5.70195C11.9927 6.05859 11.4384 6.13164 10.7122 5.99414C10.5231 5.95976 9.97312 5.79648 9.49187 5.6332C8.2114 5.19922 7.57547 5.0918 6.76765 5.16055Z"
									fill="white"
								/>
							</svg>
						</AppButtonIcon>
						<AppButtonText>App Store</AppButtonText>
					</AppButton>
					<AppButton onClick={handleGooglePlayClick}>
						<AppButtonIcon>
							<svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M2.80198 0.0299187C2.74612 0.0427837 2.70745 0.0685148 2.71604 0.0899572C2.72463 0.107111 4.87307 2.35857 7.49416 5.08606L12.2594 10.0479L12.4785 9.81627C12.5989 9.68762 13.3078 8.95 14.0512 8.17807C14.7946 7.40185 15.409 6.74571 15.4176 6.71998C15.4348 6.64707 4.25862 0.214324 3.91057 0.0985346C3.64416 0.00418758 3.10276 -0.0258322 2.80198 0.0299187Z"
									fill="white"
								/>
								<path
									d="M1.50427 0.973423C1.20349 1.44945 1.22497 0.596035 1.21208 10.8498C1.20349 17.5956 1.21208 20.1602 1.24646 20.3489C1.29802 20.6147 1.53005 21.1551 1.58591 21.1337C1.64177 21.1122 11.3656 10.9699 11.3656 10.9356C11.3656 10.9185 9.17419 8.62411 6.49724 5.83658L1.63318 0.771864L1.50427 0.973423Z"
									fill="white"
								/>
								<path
									d="M14.8586 9.11281C13.9176 10.0949 13.1484 10.914 13.1484 10.9354C13.1484 10.9654 15.3098 13.2426 16.4957 14.4649L16.6504 14.6235L18.2789 13.6844C19.177 13.1697 20.0105 12.6723 20.1309 12.5779C20.3973 12.3678 20.6852 11.9518 20.784 11.6259C20.8828 11.3042 20.8828 10.691 20.784 10.3693C20.6852 10.0391 20.393 9.62315 20.1051 9.40443C19.9332 9.27578 16.616 7.3288 16.573 7.33309C16.5688 7.33309 15.7953 8.13504 14.8586 9.11281Z"
									fill="white"
								/>
								<path d="M11.1676 12.9598C6.96953 17.3126 2.64258 21.8413 2.64258 21.8841C2.64258 22.0257 3.51484 22.0385 3.91016 21.9013C4.25391 21.7855 15.516 15.3099 15.4988 15.2413C15.4945 15.2027 13.6941 13.3071 12.4609 12.0378L12.259 11.8276L11.1676 12.9598Z" fill="white" />
							</svg>
						</AppButtonIcon>
						<AppButtonText>Play Market</AppButtonText>
					</AppButton>
				</AppButtons>
				<AppLocation>
					<AppLocationText>Де наш сервіс працює</AppLocationText>
					<AppLocationItems>
						<LocationItem>
							<LocationItemIcon>
								<svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M7.54361 0C2.22384 0 0 4.63193 0 7.34279C0 8.79427 1.02473 12.0815 6.88954 17.802C7.36047 18.1606 7.88518 17.9514 8.08866 17.802C12.8198 13.2768 15 9.47732 15 7.34279C14.7558 1.57102 9.92733 0.0426906 7.54361 0Z" fill="#724FFF" />
									<ellipse cx="7.50218" cy="6.95828" rx="3.09593" ry="3.03104" fill="#F4F1FF" />
								</svg>
							</LocationItemIcon>
							<LocationItemText>Київ</LocationItemText>
						</LocationItem>
						<LocationItem>
							<LocationItemIcon>
								<svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M7.54361 0C2.22384 0 0 4.63193 0 7.34279C0 8.79427 1.02473 12.0815 6.88954 17.802C7.36047 18.1606 7.88518 17.9514 8.08866 17.802C12.8198 13.2768 15 9.47732 15 7.34279C14.7558 1.57102 9.92733 0.0426906 7.54361 0Z" fill="#724FFF" />
									<ellipse cx="7.50218" cy="6.95828" rx="3.09593" ry="3.03104" fill="#F4F1FF" />
								</svg>
							</LocationItemIcon>
							<LocationItemText>Львів</LocationItemText>
						</LocationItem>
					</AppLocationItems>
				</AppLocation>
			</LongTermBlock>
		</RentLongTermWrapper>
	)
}

export default RentLongTerm
