import styled from 'styled-components'
import { theme } from '../../../../style/theme'

export const JostiAppWrapper = styled.div`
	border-radius: 24px;
	background: #9375ff;
	padding: 25px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 14px;
	margin-top: 120px;

	@media screen and (max-width: ${theme.point1024}) {
		margin-top: 80px;
		padding: 15px;
		border-radius: 20px;
	}

	@media screen and (max-width: ${theme.point720}) {
		grid-template-columns: 1fr;
	}
`

export const JostiAppLeft = styled.div`
	border-radius: 24px;
	background: #8363ff;
	padding: 25px;

	@media screen and (max-width: ${theme.point1024}) {
		padding: 15px;
		border-radius: 20px;
	}

	@media screen and (max-width: ${theme.point720}) {
		height: 343px;
	}

	@media screen and (max-width: ${theme.point520}) {
		display: flex;
		flex-direction: column;
	}
`

export const JostiAppTitle = styled.div`
	color: #fff;
	font-size: 30px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;

	@media screen and (max-width: ${theme.point1024}) {
		font-size: 22px;
	}
`

export const JostiAppList = styled.div`
	margin-top: 50px;

	@media screen and (max-width: ${theme.point1024}) {
		margin-top: 25px;
	}
`

export const JostiAppListItem = styled.div`
	color: #fff;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 30px;

	&:not(:first-child) {
		margin-top: 15px;
	}

	@media screen and (max-width: ${theme.point1024}) {
		font-size: 15px;
	}
`

export const JostiAppPromo = styled.span`
	border-radius: 8px;
	background: #f4c533;
	color: #fff;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	padding: 2px 10px;

	@media screen and (max-width: ${theme.point1024}) {
		font-size: 15px;
		border-radius: 6px;
		padding: 2px 5px;
	}
`

export const JostiAppDownload = styled.button`
	margin-top: 110px;
	height: 51px;
	width: 100%;
	border-radius: 30px;
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #282828;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	cursor: pointer;

	@media screen and (max-width: ${theme.point1024}) {
		margin-top: 74px;
		height: 48px;
		border-radius: 30px;
		font-size: 15px;
	}

	@media screen and (max-width: ${theme.point520}) {
		margin-top: auto;
	}
`

export const JostiAppRight = styled.div`
	border-radius: 24px;
	background: #8363ff;
	overflow: hidden;
	position: relative;
	display: flex;
	align-items: flex-end;

	@media screen and (max-width: ${theme.point1024}) {
		border-radius: 20px;
	}

	@media screen and (max-width: ${theme.point720}) {
		height: 343px;
	}
`

export const JostiAppBackground = styled.img`
	width: 100%;
`

export const JostiAppImage = styled.img`
	position: absolute;
	width: 65%;
	top: 0;
	left: 50%;
	transform: translateX(-50%);

	@media screen and (max-width: ${theme.point1024}) {
		width: 70%;
	}

	@media screen and (max-width: ${theme.point520}) {
		width: 80%;
	}
`
