import styled from 'styled-components'
import { theme } from '../../style/theme'

export const LineModalWrapper = styled.div`
	background: #8363ff;
	position: sticky;
	bottom: -90px;
	width: 100%;
	transition: all 0.3s linear;

	&.active {
		bottom: 0;
	}
`

export const LineModalInner = styled.div`
	padding: 5px 0;
	min-height: 81px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media screen and (max-width: ${theme.point1024}) {
		min-height: 70px;
		gap: 15px;
	}
`

export const LineModalText = styled.div`
	color: #fff;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	@media screen and (max-width: ${theme.point1024}) {
		font-size: 15px;
	}
`

export const LineModalButton = styled.button`
	height: 51px;
	padding: 5px 25px;
	background-color: #ffffff;
	border-radius: 30px;
	color: #282828;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	cursor: pointer;
	flex-shrink: 0;

	@media screen and (max-width: ${theme.point1024}) {
		height: 48px;
		border-radius: 30px;
		font-size: 15px;
	}
`

export const LineModalCross = styled.img`
	width: 16px;
	height: 16px;
	display: flex;
	cursor: pointer;
	flex-shrink: 0;
`
