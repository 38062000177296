import styled from 'styled-components'
import { theme } from '../../../../style/theme'

export const StartEarningWrapper = styled.div`
	display: flex;
	gap: 50px;
	align-items: flex-start;

	@media screen and (max-width: ${theme.point1024}) {
		display: block;
	}
`

export const StartEarningImage = styled.div`
	flex-shrink: 0;
	width: 50%;
	height: 509px;
	border-radius: 24px;
	overflow: hidden;

	> img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	@media screen and (max-width: ${theme.point1024}) {
		width: 100%;
		height: 329px;
		border-radius: 20px;
	}

	@media screen and (max-width: ${theme.point520}) {
		height: 234px;
	}
`

export const StartEarningInfo = styled.div`
	@media screen and (max-width: ${theme.point1024}) {
		margin-top: 25px;
	}
`

export const EarningList = styled.div``

export const EarningItem = styled.div`
	display: flex;
	align-items: flex-start;
	gap: 33px;
	margin-top: 25px;

	@media screen and (max-width: ${theme.point1024}) {
		gap: 25px;
	}
`

export const EarningItemIcon = styled.img`
	width: 38px;
	height: 38px;
`

export const EarningItemInfo = styled.div`
	padding-top: 8px;
`

export const EarningItemTitle = styled.div`
	color: #282828;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`

export const EarningItemText = styled.div`
	margin-top: 15px;
	color: #282828;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
`

export const EarningButton = styled.button`
	height: 51px;
	margin-top: 50px;
	padding: 5px 30px;
	border-radius: 30px;
	background: #724fff;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: #ffffff;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	cursor: pointer;

	@media screen and (max-width: ${theme.point1024}) {
		width: 100%;
		height: 48px;
		font-size: 15px;
	}
`
