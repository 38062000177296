import styled from 'styled-components'
import { HashLink } from 'react-router-hash-link'

export const MobileHeaderWrapper = styled.div`
	background: #f5f4f9;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	z-index: -1;
	transition: all 0.2s linear;

	&.active {
		z-index: 2;
		opacity: 1;
	}
`

export const MobileHeaderNav = styled.div`
	margin-top: 143px;
	display: flex;
	flex-direction: column;
	gap: 25px;
`

export const MobileHeaderApp = styled.div``

export const MobileHeaderAppText = styled.div`
	margin-top: 80px;
	color: #282828;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`

export const MobileHeaderAppButtons = styled.div`
	margin-top: 25px;
	gap: 10px;
	display: flex;
	align-items: center;
`

export const AppButton = styled.button`
	background: #282828;
	border-radius: 12px;
	height: 48px;
	display: flex;
	align-items: center;
	padding: 5px 15px;
	gap: 10px;
	cursor: pointer;
`

export const AppButtonIcon = styled.div`
	width: 18px;
	height: 18px;

	> svg {
		width: 100%;
		height: 100%;
	}
`

export const AppButtonText = styled.div`
	color: #ffffff;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`

export const MobileHeaderContacts = styled.div`
	margin-top: 25px;
	border-top: 1px solid #cecece80;
`

export const MobileHeaderContactsTitle = styled.div`
	color: #282828;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-top: 25px;
`

export const MobileHeaderContactsLink = styled(HashLink)`
	text-decoration: none;
	margin-top: 20px;
	color: #282828;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	display: flex;
	align-items: center;
	gap: 10px;

	> img {
		width: 13px;
		height: 13px;
	}

	&:hover {
		opacity: 0.5;
	}
`

export const MobileHeaderSocialMedia = styled.div`
	margin-top: 50px;
	display: flex;
	align-items: center;
	gap: 10px;
`

export const SocialMediaLink = styled.div`
	width: 38px;
	height: 38px;
	border-radius: 24px;
	background-color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	&:hover {
		opacity: 0.5;
	}
`

export const SocialMediaIcon = styled.img`
	width: 20px;
	height: 20px;
`
