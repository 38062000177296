import React from 'react'
import { Faqs, JostiFaqTitle, JostiFaqWrapper } from './index.styled'
import FaqItem from './components/FaqItem'

const JostiFaq = () => {
	return (
		<JostiFaqWrapper>
			<JostiFaqTitle id="faq">Часті запитання</JostiFaqTitle>
			<Faqs>
				<FaqItem
					text="1. Чому я плачу за перегляд?"
					answer="Застосунок Josti пропонує для вас виключно об'єкти без комісії та відповідає за наповнення. Комісія посередників на ринку України складає від 50 до 100%. Забронювавши, навіть декілька переглядів на нашій платформі ви маєте можливість зекономити до 90% ваших коштів."
				/>
				<FaqItem
					text="2. Що робити, якщо об’єкт виявися не актуальним?"
					answer="Josti відповідає за наповнення та гарантує актуальність об'єктів. То ж , якщо заброньований вами об'єкт виявився не актуальним , натисніть кнопку «не актуальний об'єкт» і кошти автоматично повернуться на ваш баланс Josti."
				/>
				<FaqItem
					text="3. Чи проводить Josti перегляди?"
					answer="Josti це додаток який штучним інтелектом перевіряє квартири на актуальність, дає можливість орендувати без комісії. В самій оренді ми участь не приймаємо. Питання щодо договору і тд ви вирішуєте з власником, або представником власника. Контакт зʼявиться в застосунку одразу після оплати."
				/>
				<FaqItem
					text="4. У додатку Josti квартири тільки від власники?"
					answer="В додатку може бути обʼєкт як від власника , так і від представника власника ( ріелтор) , але суть залишається не змінною - для клієнта ( орендаря) обʼєкти без комісії. Якщо обєкт виявиться не актуальним або з комісією , в додатку буде доступна кнопка «не актуальний обʼєкт» і кошти автоматично повернуться на ваш баланс Josti."
				/>
			</Faqs>
		</JostiFaqWrapper>
	)
}

export default JostiFaq
