import React, { useEffect, useState } from 'react'
import { PageContainer } from '../../pages/LandingPage/index.styled'
import { LineModalButton, LineModalCross, LineModalInner, LineModalText, LineModalWrapper } from './index.styled'
import cross from '../../img/modal/cross.png'
import { useWindowBreakPoint } from '../../hooks/useWindowBreakPoint'
import { theme } from '../../style/theme'
import { hostUiUrl } from '../../urls'

const LineModal = () => {
	const [isOpen, setIsOpen] = useState(false)
	const isSmallTablet = useWindowBreakPoint(theme.point720)

	const layout = isOpen ? 'active' : ''

	const handleNavigateToHost = () => {
		window.open(`${hostUiUrl}`, '_blank')
	}

	useEffect(() => {
		setTimeout(() => {
			setIsOpen(true)
		}, 2000)
	}, [])

	const handleClose = () => {
		setIsOpen(false)
	}

	return (
		!isSmallTablet && (
			<LineModalWrapper className={layout}>
				<PageContainer>
					<LineModalInner>
						<LineModalText>
							<strong>Бажаєте здати житло?</strong> Ми допоможемо вам отримати стабільний дохід на оренді
						</LineModalText>
						<LineModalButton onClick={handleNavigateToHost}>Здати житло</LineModalButton>
						<LineModalCross src={cross} alt="Cross" onClick={handleClose} />
					</LineModalInner>
				</PageContainer>
			</LineModalWrapper>
		)
	)
}

export default LineModal
