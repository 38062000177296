import styled from 'styled-components'
import { theme } from '../../style/theme'

export const PageWrapper = styled.div`
	position: relative;
	padding-top: 0.01%;
`

export const PageContainer = styled.div`
	padding-top: 0.01%;
	max-width: 1204px;
	margin-left: auto;
	margin-right: auto;

	@media screen and (max-width: ${theme.point1660}) {
		max-width: 1204px;
	}

	@media screen and (max-width: ${theme.point1440}) {
		max-width: 1104px;
	}

	@media screen and (max-width: ${theme.point1280}) {
		padding-right: 30px;
		padding-left: 30px;
	}

	@media screen and (max-width: ${theme.point520}) {
		padding-right: 15px;
		padding-left: 15px;

		&.disable-padding {
			padding-left: 0;
			padding-right: 0;
		}
	}
`

export const LandingHeading = styled.h2`
	margin: 120px auto 50px;
	max-width: 320px;
	color: #282828;
	text-align: center;
	font-size: 30px;
	font-style: normal;
	font-weight: 500;
	font-family: 'Roboto', sans-serif;
	line-height: normal;

	&.full-width {
		max-width: 100%;
	}

	@media screen and (max-width: ${theme.point1024}) {
		margin-top: 80px;
		margin-bottom: 25px;
	}

	@media screen and (max-width: ${theme.point520}) {
		font-size: 22px;
		text-align: left;
		margin-left: 0;
		margin-right: 0;
	}
`
