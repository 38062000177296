import React, { useEffect, useState } from 'react'
import { ActualWrapper, FlatBlock, FlatBlocks, FlatCity, FlatFooter, FlatImage, FlatInfo, FlatList, FlatMetroPoint, FlatPrice, FlatWrapper, MoreFlatsButton } from './index.styled'
import { useWindowBreakPoint } from '../../../../hooks/useWindowBreakPoint'
import { theme } from '../../../../style/theme'
import { getAllFlats } from '../../../../api/app/flat'

const Actual = ({ setQrIsOpen }) => {
	const [flats, setFlats] = useState([])
	const isTablet = useWindowBreakPoint(theme.point1024)

	useEffect(() => {
		getAllFlats({
			page: 0,
			size: 3,
			city: 'kiev',
			priceFrom: 18000,
			priceTo: 25000,
			currency: 'UAH'
		}).then(res => {
			setFlats(res.data.flats)
		})
	}, [])

	const handleNavigateToApp = () => {
		if (isTablet) {
			window.open('https://onelink.to/dc2jrs', '_blank')
		} else {
			setQrIsOpen(true)
		}
	}

	return (
		<ActualWrapper>
			<FlatList>
				{flats.slice(0, 3).map(flat => (
					<FlatWrapper key={flat._id} onClick={handleNavigateToApp}>
						<FlatImage src={flat.images?.[0]} />
						<FlatInfo>
							<FlatCity>{flat.cityText}</FlatCity>
							{/*<FlatStreet>*/}
							{/*	<img src={marker} alt="Marker" />*/}
							{/*	{flat.streetText}*/}
							{/*</FlatStreet>*/}
							<FlatBlocks>
								{flat.districtText && <FlatBlock>{flat.districtText}</FlatBlock>}
								{flat.metroStationsText?.map(metro => (
									<FlatBlock key={metro.text}>
										<FlatMetroPoint style={{ backgroundColor: metro.color }}>{metro.branch}</FlatMetroPoint>
										{metro.text}
									</FlatBlock>
								))}
								{flat.residenceText && <FlatBlock>{flat.residenceText}</FlatBlock>}
							</FlatBlocks>
							<FlatFooter>
								<FlatPrice>
									{flat.priceText}
									<span>/міс.</span>
								</FlatPrice>
							</FlatFooter>
						</FlatInfo>
					</FlatWrapper>
				))}
			</FlatList>
			<MoreFlatsButton onClick={handleNavigateToApp}>Більше оголошень</MoreFlatsButton>
		</ActualWrapper>
	)
}

export default Actual
