import React from 'react'
import { JostiAppBackground, JostiAppDownload, JostiAppImage, JostiAppLeft, JostiAppList, JostiAppListItem, JostiAppPromo, JostiAppRight, JostiAppTitle, JostiAppWrapper } from './index.styled'
import app from '../../../../img/app/app.webp'
import bg from '../../../../img/app/bg.webp'
import { useWindowBreakPoint } from '../../../../hooks/useWindowBreakPoint'
import { theme } from '../../../../style/theme'

const JostiApp = ({ setQrIsOpen }) => {
	const isTablet = useWindowBreakPoint(theme.point1024)

	const handleNavigateToApp = () => {
		if (isTablet) {
			window.open('https://onelink.to/dc2jrs', '_blank')
		} else {
			setQrIsOpen(true)
		}
	}

	return (
		<JostiAppWrapper>
			<JostiAppLeft>
				<JostiAppTitle>Josti у Києві!</JostiAppTitle>
				<JostiAppList>
					<JostiAppListItem>1. Завантаж додаток Josti.</JostiAppListItem>
					<JostiAppListItem>
						2. Введи промокод: <JostiAppPromo>GMN2683</JostiAppPromo>
					</JostiAppListItem>
					<JostiAppListItem>3. Отримуй знижку на оренду.</JostiAppListItem>
				</JostiAppList>
				<JostiAppDownload onClick={handleNavigateToApp}>Завантажити додаток</JostiAppDownload>
			</JostiAppLeft>
			<JostiAppRight>
				<JostiAppBackground src={bg} />
				<JostiAppImage src={app} />
			</JostiAppRight>
		</JostiAppWrapper>
	)
}

export default JostiApp
