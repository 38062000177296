import styled from 'styled-components'
import { theme } from '../../../../style/theme'

export const JostiFaqWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 120px;

	@media screen and (max-width: ${theme.point1024}) {
		margin-top: 80px;
		display: block;
	}
`

export const JostiFaqTitle = styled.div`
	color: #282828;
	font-size: 30px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;

	@media screen and (max-width: ${theme.point1024}) {
		font-size: 22px;
		text-align: center;
	}
`

export const Faqs = styled.div`
	flex-shrink: 0;
	width: 66%;

	@media screen and (max-width: ${theme.point1024}) {
		margin-top: 25px;
		width: 100%;
	}
`

export const FaqItemWrapper = styled.div`
	cursor: pointer;

	&:not(:first-child) {
		margin-top: 15px;
	}

	@media screen and (max-width: ${theme.point1024}) {
		&:not(:first-child) {
			margin-top: 10px;
		}
	}
`

export const FaqItemText = styled.div`
	border-radius: 12px;
	background: #fff;
	padding: 5px 25px;
	height: 71px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media screen and (max-width: ${theme.point1024}) {
		height: 48px;
		padding: 5px 15px;
	}
`

export const FaqText = styled.div`
	color: #282828;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	user-select: none;

	@media screen and (max-width: ${theme.point1024}) {
		font-size: 15px;
	}
`

export const FaqPlus = styled.img`
	width: 21px;
	height: 21px;
	user-select: none;

	@media screen and (max-width: ${theme.point1024}) {
		width: 18px;
		height: 18px;
	}
`

export const FaqItemAnswer = styled.div`
	border-radius: 12px;
	background: #fff;
	color: #282828;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	user-select: none;
	transition: all 0.2s linear;
	max-height: 0;
	overflow: hidden;
	padding: 0 24px;

	&.active {
		transition: all 0.2s linear;
		margin-top: 15px;
		max-height: 300px;
		padding: 24px;
	}

	@media screen and (max-width: ${theme.point1024}) {
		font-size: 13px;
		padding: 0 15px;

		&.active {
			padding: 15px;
			margin-top: 10px;
		}
	}
`
