import styled from 'styled-components'
import { theme } from '../../style/theme'
import { HashLink } from 'react-router-hash-link'

export const HeaderWrapper = styled.header`
	width: 100%;
	height: 71px;
	margin-top: 63px;

	@media screen and (max-width: ${theme.point1024}) {
		margin-top: 56px;
		height: 48px;
	}

	@media screen and (max-width: ${theme.point720}) {
		position: relative;
	}

	@media screen and (max-width: ${theme.point520}) {
		position: absolute;
		z-index: 3;
		top: 15px;
		left: 15px;
		margin-top: 0;
		width: calc(100% - 30px);

		&.mobile-header-active {
			position: fixed;
		}
	}
`

export const HeaderBody = styled.div`
	width: 100%;
	height: inherit;
	background-color: #ffffff;
	border-radius: 50px;
	padding: 5px 10px 5px 25px;
	display: flex;
	align-items: center;

	@media screen and (max-width: ${theme.point1024}) {
		padding: 1px 5px 1px 25px;
	}

	@media screen and (max-width: ${theme.point720}) {
		&.mobile-header-active {
			position: fixed;
			z-index: 3;
			top: 56px;
			left: 30px;
			margin-top: 0;
			width: calc(100% - 60px);
		}
	}

	@media screen and (max-width: ${theme.point520}) {
		position: static;
		width: 100%;

		&.mobile-header-active {
			position: static;
			width: 100%;
		}
	}
`

export const HeaderLogo = styled.div`
	margin-right: auto;
	width: 79px;
	height: 35px;

	> svg {
		width: 100%;
	}

	@media screen and (max-width: ${theme.point1024}) {
		width: 54px;
		height: 22px;
	}
`

export const HeaderNav = styled.div`
	display: flex;
	align-items: center;
`

export const NavLinkWrapper = styled.div`
	position: relative;
	top: 0;

	@media screen and (max-width: ${theme.point720}) {
		display: none;
	}
`

export const NavLinkButton = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	cursor: pointer;
	height: 50px;
	padding: 5px 25px;
	position: relative;

	&.active {
		border-radius: 30px;
		background: #f8f8f8;
	}

	@media screen and (max-width: ${theme.point1024}) {
		height: 38px;
	}

	@media screen and (max-width: ${theme.point820}) {
		padding-right: 20px;
		padding-left: 15px;
	}
`

export const NavLinkText = styled.div`
	color: #282828;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;

	@media screen and (max-width: ${theme.point1024}) {
		font-size: 15px;
	}
`

export const NavLinkArrow = styled.div`
	width: 13px;
	height: 13px;
	display: flex;

	> img {
		width: 100%;
		height: 100%;
	}
`

export const NavLinkItemsWrapper = styled.div`
	width: 100%;
	padding-top: 20px;
	position: absolute;
	z-index: -1;
	opacity: 0;

	&.active {
		opacity: 1;
		z-index: 1;
	}

	@media screen and (max-width: ${theme.point1024}) {
		padding-top: 10px;
	}
`

export const NavLinkItems = styled.ul`
	background: #ffffff;
	border-radius: 10px;
	width: 100%;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
	padding: 17.5px 0;

	@media screen and (max-width: ${theme.point1024}) {
		padding: 10px 0;
	}
`

export const NavLinkItem = styled.li`
	display: block;
`

export const NavLinkItemLink = styled(HashLink)`
	display: block;
	text-decoration: none;
	color: #404040;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	cursor: pointer;
	padding: 7.5px 20px 7.5px 25px;
	user-select: none;

	&:hover {
		opacity: 0.5;
	}

	@media screen and (max-width: ${theme.point1024}) {
		font-size: 13px;
	}
`

export const HeaderButtons = styled.div`
	margin-left: auto;
	display: flex;
	align-items: center;
	gap: 15px;

	@media screen and (max-width: ${theme.point1024}) {
		gap: 25px;
	}

	@media screen and (max-width: ${theme.point820}) {
		gap: 10px;
	}

	@media screen and (max-width: ${theme.point520}) {
		gap: 0;
		margin-left: 0;
	}
`

export const WriteUsButton = styled.button`
	height: 51px;
	padding: 5px 25px;
	background-color: #f8f8f8;
	border-radius: 30px;
	color: #282828;
	font-size: 18px;
	font-weight: 500;
	font-style: normal;
	cursor: pointer;
`

export const HeaderSupportIcon = styled.button`
	background-color: transparent;
	margin-right: 30px;

	@media screen and (max-width: ${theme.point1024}) {
		margin-right: 0;
	}

	@media screen and (max-width: ${theme.point520}) {
		margin-right: 18px;
	}
`

export const RentButton = styled.button`
	height: 51px;
	padding: 5px 25px;
	background-color: #724fff;
	border-radius: 30px;
	color: #ffffff;
	font-size: 18px;
	font-weight: 500;
	font-style: normal;
	cursor: pointer;

	@media screen and (max-width: ${theme.point1024}) {
		height: 38px;
		font-size: 15px;
	}

	@media screen and (max-width: ${theme.point520}) {
		margin-right: 10px;
	}
`
