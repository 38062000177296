import styled from 'styled-components'
import { HashLink } from 'react-router-hash-link'

export const NavLinkWrapper = styled.div`
	position: relative;
	top: 0;
`

export const NavLinkButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
	cursor: pointer;
	height: 21px;
	position: relative;
`

export const NavLinkText = styled.div`
	color: #282828;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`

export const NavLinkArrow = styled.div`
	width: 13px;
	height: 13px;
	display: flex;
	transition: all 0.2s linear;

	> img {
		width: 100%;
		height: 100%;
	}

	&.active {
		transform: rotate(180deg);
	}
`

export const NavLinkItemsWrapper = styled.div`
	width: 100%;
	margin-top: 25px;
`

export const NavLinkItems = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 15px;
`

export const NavLinkItem = styled(HashLink)`
	display: block;
	text-decoration: none;
	color: #282828;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	cursor: pointer;
	user-select: none;
`
