import styled from 'styled-components'
import { theme } from '../../../../style/theme'

export const WhyJostiWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 15px;

	@media screen and (max-width: ${theme.point1024}) {
		grid-template-columns: 1fr;
	}
`

export const WhyJostiBlock = styled.div`
	border-radius: 24px;
	background: #ffffff;
	padding: 25px;

	@media screen and (max-width: ${theme.point1024}) {
		padding: 15px;
		min-height: 237px;
	}

	@media screen and (max-width: ${theme.point520}) {
		min-height: 271px;
	}
`

export const WhyJostiBlockNumber = styled.div`
	width: 38px;
	height: 38px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 12px;
	background: #f5f4f9;
	color: #282828;
	text-align: center;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`

export const WhyJostiBlockTitle = styled.div`
	margin-top: 25px;
	color: #282828;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`

export const WhyJostiBlockText = styled.div`
	margin-top: 15px;
	color: #282828;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
`

export const WhyJostiDescription = styled.div`
	display: flex;
	align-items: flex-start;
	gap: 10px;
	margin-top: 10px;
`

export const WhyJostiDescriptionArrow = styled.div``

export const WhyJostiDescriptionText = styled.div`
	color: #282828;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
`
