import styled from 'styled-components'
import { theme } from '../../style/theme'

export const QrCodeModalWrapper = styled.div`
	background: rgba(40, 40, 40, 0.3);
	position: fixed;
	top: 0;
	left: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: all 0.2s linear;

	&.active {
		z-index: 10;
		opacity: 1;
	}
`

export const QrCodeModalBody = styled.div`
	width: 545px;
	min-height: 400px;
	border-radius: 24px;
	background: #fff;
	padding: 25px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	align-items: center;
	opacity: 0;
	transition: all 0.2s linear;

	&.active {
		opacity: 1;
	}

	@media screen and (max-width: ${theme.point1024}) {
		border-radius: 20px;
		padding: 15px;
		min-height: 350px;
		width: 500px;
	}
`

export const QrCodeModalCross = styled.div`
	width: 56px;
	height: 56px;
	border-radius: 12px;
	background: #f5f4f9;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 25px;
	right: 25px;
	cursor: pointer;

	> img {
		width: 16px;
		height: 16px;
	}

	@media screen and (max-width: ${theme.point1024}) {
		width: 48px;
		height: 48px;
		top: 15px;
		right: 15px;
	}
`

export const QrCodeBlock = styled.div`
	width: 185px;
	height: 185px;
	border-radius: 24px;
	border: 1px solid #cecece;
	overflow: hidden;
	padding: 10px;
	margin-top: 44px;

	> img {
		width: 100%;
		height: 100%;
	}

	@media screen and (max-width: ${theme.point1024}) {
		border-radius: 20px;
	}
`

export const QrCodeText = styled.div`
	margin-top: 25px;
	color: #282828;
	text-align: center;
	font-size: 22px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	width: 70%;

	@media screen and (max-width: ${theme.point1024}) {
		font-size: 18px;
	}
`
