import React from 'react'
import { BookingStep, BookingStepImage, BookingStepImages, BookingStepInfo, BookingStepNumber, BookingStepsWrapper, BookingStepText, BookingStepTitle } from './index.styled'
import step1 from '../../../../img/steps/step1.webp'
import step2 from '../../../../img/steps/step2.webp'
import step3 from '../../../../img/steps/step3.webp'
import { useWindowBreakPoint } from '../../../../hooks/useWindowBreakPoint'
import { theme } from '../../../../style/theme'

const BookingSteps = () => {
	const isSmallTablet = useWindowBreakPoint(theme.point720)

	return (
		<BookingStepsWrapper>
			<BookingStep>
				<BookingStepImages className="step1">
					<BookingStepNumber>Крок 1</BookingStepNumber>
					<BookingStepImage src={step1} />
				</BookingStepImages>
				<BookingStepInfo>
					<BookingStepTitle>Оберіть оголошення</BookingStepTitle>
					<BookingStepText>
						Тут все просто: зайшли у додаток, відфільтрували по ціні, районам, метро або “Без комісії”, переглянули детальну інформацію про житло, і натиснули на кнопку <b>“Бронювати”</b>.
					</BookingStepText>
				</BookingStepInfo>
			</BookingStep>
			<BookingStep>
				<BookingStepImages className="step2">
					<BookingStepNumber>Крок 2</BookingStepNumber>
					<BookingStepImage src={step2} />
				</BookingStepImages>
				<BookingStepInfo>
					<BookingStepTitle>Ознайомтесь з деталями бронювання</BookingStepTitle>
					<BookingStepText>
						Перегляньте інформацію в онбориднгу та переходьте до бронювання перегляду. <br />
						<br />
						Оберіть бажаний спосіб оплати, додайте промокод при наявності, а після оплатіть в 1 клік.
					</BookingStepText>
				</BookingStepInfo>
			</BookingStep>
			<BookingStep className="step3">
				<BookingStepImages className="step3">
					{isSmallTablet && <BookingStepNumber className="step3">Крок 3</BookingStepNumber>}
					<BookingStepImage src={step3} />
				</BookingStepImages>
				<BookingStepInfo>
					{!isSmallTablet && <BookingStepNumber className="step3">Крок 3</BookingStepNumber>}
					<BookingStepTitle>Прямуйте на перегляд</BookingStepTitle>
					<BookingStepText>
						Після оплати, на “Головній сторінці”, у вас з’явиться ваша квартира. Далі натискайте кнопку <b>“Зв’язатись з власником”</b> та домовляйтесь про перегляд.
						<br />
						<br />
						Після успішного заселення натисніть на кнопку <b>“Завершити перегляд” - “Успішне заселення”</b>.
					</BookingStepText>
				</BookingStepInfo>
			</BookingStep>
		</BookingStepsWrapper>
	)
}

export default BookingSteps
