import styled from 'styled-components'

export const HeaderBurgerWrapper = styled.div`
	width: 38px;
	height: 38px;
	border-radius: 24px;
	background: #724fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 2px;
`

export const HeaderBurgerLine = styled.div`
	width: 14px;
	height: 2px;
	background-color: #fff;
	border-radius: 1px;
	transition: all 0.2s linear;

	&.active {
		&:nth-child(1) {
			transform: rotate(45deg) translate(2px, 2px) scale(1.2);
		}

		&:nth-child(2) {
			display: none;
		}

		&:nth-child(3) {
			transform: rotate(-45deg) translate(1px, -1px) scale(1.2);
		}
	}
`
