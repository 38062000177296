import React from 'react'
import { AppButton, AppButtonIcon, AppButtonText, FooterAppButtons, FooterInfoBlock, FooterInfoBlockLink, FooterInfoBlocks, FooterInfoBlockTitle, FooterLogo, FooterSmallLink, FooterSmallLinks, FooterSocialMedia, FooterWrapper, SocialMediaIcon, SocialMediaLink } from './index.styled'
import { PageContainer } from '../../pages/LandingPage/index.styled'
import instagram from '../../img/footer/instagram.png'
import telegram from '../../img/footer/telegram.png'
import viber from '../../img/footer/viber.png'
import phone from '../../img/footer/phone.png'
import email from '../../img/footer/email.png'
import { hostUiUrl } from '../../urls'

const Footer = () => {
	const handleNavigateHome = () => {
		window.open('/', '_self')
	}

	const handleNavigateToHost = () => {
		window.open(`${hostUiUrl}`, '_blank')
	}

	const handlePhoneClick = () => {
		window.open('tel:+380977957122', '_self')
	}

	const handleEmailClick = () => {
		window.open('mailto:support@josti.com.ua', '_self')
	}

	const handleAppStoreClick = () => {
		window.open('https://apps.apple.com/ua/app/josti-%D0%B4%D0%BE%D0%B2%D0%B3%D0%BE%D1%81%D1%82%D1%80%D0%BE%D0%BA%D0%BE%D0%B2%D0%B0-%D0%BE%D1%80%D0%B5%D0%BD%D0%B4%D0%B0/id6446787293', '_blank')
	}

	const handleGooglePlayClick = () => {
		window.open('https://play.google.com/store/apps/details?id=com.gmnapp&pcampaignid=web_share', '_blank')
	}

	return (
		<FooterWrapper>
			<PageContainer>
				<FooterLogo onClick={handleNavigateHome}>
					<svg width="91" height="40" viewBox="0 0 91 40" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M26.2675 11.4678C22.3779 12.435 19.5412 15.3364 18.5761 19.3298C18.196 20.9365 18.196 23.5571 18.5761 25.1638C19.278 28.0809 21.1058 30.5923 23.5623 31.9806C26.5891 33.7121 31.1659 33.6029 34.0611 31.731C37.1318 29.7344 38.7841 26.8017 39.0034 22.8708C39.2959 17.7387 36.4884 13.3397 31.9263 11.7486C30.5225 11.265 27.6566 11.1247 26.2675 11.4678ZM30.2301 18.2534C31.6046 18.9242 32.438 20.7025 32.2918 22.6524C32.1895 23.8847 31.9409 24.5711 31.3267 25.3354C30.6395 26.1466 29.806 26.5365 28.6655 26.5365C26.3552 26.5365 24.9807 24.9298 24.9807 22.2468C24.9807 20.3437 25.6095 19.0646 26.8962 18.347C27.7735 17.8635 29.3235 17.8167 30.2301 18.2534Z"
							fill="#724FFF"
						/>
						<path
							d="M27.6105 0.186835C26.8666 0.498504 13.5167 10.9498 13.1749 11.49C12.4109 12.6951 12.612 14.4197 13.6775 15.4586C14.3611 16.1442 15.9293 16.3936 16.7134 15.978C16.9748 15.8326 19.7091 13.734 22.8053 11.3446C25.9015 8.93432 28.5353 6.9812 28.656 6.9812C28.7766 6.9812 31.4104 8.93432 34.5066 11.3446C37.6028 13.734 40.3371 15.8326 40.5985 15.978C41.3826 16.3936 42.9508 16.1442 43.6344 15.4586C44.7 14.4197 44.901 12.6951 44.137 11.49C43.7751 10.9082 30.4051 0.477726 29.6411 0.186835C29.0179 -0.0625 28.2137 -0.0625 27.6105 0.186835Z"
							fill="#724FFF"
						/>
						<path d="M82.5723 22.2434V32.8174H86.0791H89.586V22.2434V11.6693H86.0791H82.5723V22.2434Z" fill="#724FFF" />
						<path
							d="M4.00195 11.9787C3.83692 12.1109 3.71315 16.9573 3.71315 22.729C3.71315 32.6421 3.67189 33.2589 2.92926 33.6995C2.47543 33.9198 1.65029 34.1401 1.07269 34.1401C0.0412572 34.1401 0 34.2282 0 37.048V39.9999L3.42435 39.7796C6.10607 39.6474 7.1375 39.3831 8.12767 38.6341C10.4793 36.8277 10.7269 35.3737 10.7269 22.9052V11.6703H7.50881C5.73475 11.6703 4.12572 11.8025 4.00195 11.9787Z"
							fill="#724FFF"
						/>
						<path
							d="M49.4434 12.1564C47.3392 13.3459 46.2666 15.3286 46.2666 18.0161C46.2666 21.9374 48.742 24.4046 53.2803 25.0655C55.5907 25.418 56.3746 25.9026 56.0445 26.8278C55.6319 27.9293 52.8677 27.7971 52.5789 26.6516C52.3726 25.8586 52.0013 25.7704 48.907 25.7704H45.4414L45.6889 26.96C46.6791 31.4099 49.2783 33.2604 54.518 33.2604C58.1486 33.2604 60.0877 32.5114 61.8618 30.4847C63.5946 28.5021 63.8834 24.713 62.4394 22.3339C61.6142 20.9681 60.1702 20.2191 56.9934 19.6023C53.4866 18.8533 52.6614 18.4127 53.3215 17.5756C54.0229 16.6944 55.2606 16.8266 55.8382 17.8399C56.292 18.5889 56.8284 18.7211 59.5514 18.7211H62.7694V17.4434C62.7694 15.7251 61.2017 13.4341 59.2626 12.2445C57.0347 10.8787 51.795 10.8346 49.4434 12.1564Z"
							fill="#724FFF"
						/>
						<path
							d="M68.1331 8.80823V11.672H66.2765H64.4199V14.9764V18.2808H66.2765H68.1331V22.9951C68.1331 30.7934 69.4533 32.5117 75.8069 32.732L79.6851 32.9082V29.7801V26.6519H77.6222C75.0643 26.6519 74.7342 26.0791 74.7342 21.6292V18.2808H77.2097H79.6851V14.9764V11.672H77.2097H74.7342V8.80823V5.94443H71.4336H68.1331V8.80823Z"
							fill="#724FFF"
						/>
						<path d="M83.1935 3.52022C82.4922 4.22515 82.1621 5.06226 82.1621 6.16372C82.1621 8.49882 83.6474 9.90869 86.0815 9.90869C88.5157 9.90869 90.001 8.49882 90.001 6.16372C90.001 3.82862 88.5157 2.41875 86.0815 2.41875C84.6375 2.41875 83.9362 2.6831 83.1935 3.52022Z" fill="#724FFF" />
						<path d="M4.70208 3.4735C3.83568 4.26656 3.54688 4.88338 3.54688 6.16107C3.54688 10.655 10.3543 11.404 11.3857 7.04224C12.2521 3.34133 7.50757 0.829997 4.70208 3.4735Z" fill="#724FFF" />
					</svg>
				</FooterLogo>
				<FooterInfoBlocks>
					<FooterInfoBlock>
						<FooterInfoBlockTitle>Наш сервіс</FooterInfoBlockTitle>
						<FooterInfoBlockLink to="/#about-us" scroll={el => el.scrollIntoView({ behavior: 'auto', block: 'start' })}>
							Про нас
						</FooterInfoBlockLink>
						<FooterInfoBlockLink to="/#booking" scroll={el => el.scrollIntoView({ behavior: 'auto', block: 'start' })}>
							Як забронювати
						</FooterInfoBlockLink>
						<FooterInfoBlockLink to="/#faq" scroll={el => el.scrollIntoView({ behavior: 'auto', block: 'start' })}>
							Часті питання
						</FooterInfoBlockLink>
					</FooterInfoBlock>
					<FooterInfoBlock className="host">
						<FooterInfoBlockTitle>Співпрацюйте з Josti</FooterInfoBlockTitle>
						<FooterInfoBlockLink onClick={handleNavigateToHost}>Зареєструватись власником</FooterInfoBlockLink>
					</FooterInfoBlock>
					<FooterInfoBlock>
						<FooterInfoBlockTitle>Контакти</FooterInfoBlockTitle>
						<FooterInfoBlockLink onClick={handlePhoneClick}>
							<img src={phone} alt="Phone" />
							+380 97 795 7122
						</FooterInfoBlockLink>
						<FooterInfoBlockLink onClick={handleEmailClick}>
							<img src={email} alt="Email" />
							support@josti.com.ua
						</FooterInfoBlockLink>
						<FooterSocialMedia>
							<SocialMediaLink onClick={() => window.open('https://www.instagram.com/josticom/', '_blank')}>
								<SocialMediaIcon src={instagram} alt="Instagram" />
							</SocialMediaLink>
							<SocialMediaLink onClick={() => window.open('https://t.me/josticom', '_blank')}>
								<SocialMediaIcon src={telegram} alt="Telegram" />
							</SocialMediaLink>
							<SocialMediaLink onClick={() => window.open('https://invite.viber.com/?g2=AQA%2FOKk%2BkK955lF3rvFyhmm%2FneYXFTWqmyBUj93sUz35aHzdT47clzce6b40lYiP', '_blank')}>
								<SocialMediaIcon src={viber} alt="Viber" />
							</SocialMediaLink>
						</FooterSocialMedia>
					</FooterInfoBlock>
					<FooterInfoBlock className="download">
						<FooterInfoBlockTitle>Завантажуй ось тут:</FooterInfoBlockTitle>
						<FooterAppButtons>
							<AppButton onClick={handleAppStoreClick}>
								<AppButtonIcon>
									<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M14.503 0.0816407C13.7295 0.275 12.9948 0.657422 12.3373 1.21602C11.233 2.15273 10.5284 3.70391 10.6358 4.96289L10.6659 5.32812L10.9924 5.29805C11.8604 5.2293 12.6811 4.87695 13.4459 4.24102C14.142 3.66953 14.7737 2.64258 15.0444 1.6543C15.1131 1.40078 15.1432 1.13867 15.1561 0.653125L15.1776 0L14.9928 0.00429678C14.8897 0.00429678 14.6705 0.038672 14.503 0.0816407Z"
											fill="white"
										/>
										<path
											d="M6.76765 5.16055C4.72234 5.33672 2.66843 7.06836 1.97664 9.19961C1.52976 10.5832 1.46961 12.3062 1.80906 14.0508C2.12273 15.6449 2.72429 17.1832 3.61375 18.6484C4.55906 20.2039 5.52586 21.2609 6.4239 21.7164C6.845 21.927 7.16297 22 7.67 22C8.18562 22 8.50359 21.9227 9.20398 21.6219C10.1149 21.2309 10.6778 21.0977 11.4298 21.0977C12.1516 21.0977 12.6501 21.218 13.5309 21.6004C14.2657 21.9184 14.5794 22 15.0864 22C15.5677 22 15.9329 21.9055 16.3712 21.6691C17.4626 21.0762 18.8934 19.2672 19.9333 17.166C20.1696 16.6934 20.3716 16.268 20.3845 16.2207C20.4059 16.1477 20.3458 16.1004 19.9634 15.9113C18.7903 15.3141 17.9782 14.3645 17.5571 13.1055C17.3552 12.4953 17.2993 12.0656 17.3294 11.3266C17.3552 10.6262 17.4712 10.1406 17.7419 9.56055C18.1157 8.76133 18.7473 8.04375 19.4735 7.59258C19.6325 7.49375 19.7657 7.39492 19.7657 7.37773C19.7657 7.31328 19.3145 6.82773 18.9923 6.54844C18.395 6.02851 17.643 5.58164 17.0157 5.37969C16.4872 5.20781 16.1134 5.14766 15.5118 5.14766C14.661 5.14766 14.154 5.25508 12.9552 5.70195C11.9927 6.05859 11.4384 6.13164 10.7122 5.99414C10.5231 5.95976 9.97312 5.79648 9.49187 5.6332C8.2114 5.19922 7.57547 5.0918 6.76765 5.16055Z"
											fill="white"
										/>
									</svg>
								</AppButtonIcon>
								<AppButtonText>App Store</AppButtonText>
							</AppButton>
							<AppButton onClick={handleGooglePlayClick}>
								<AppButtonIcon>
									<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M2.80198 0.0299187C2.74612 0.0427837 2.70745 0.0685148 2.71604 0.0899572C2.72463 0.107111 4.87307 2.35857 7.49416 5.08606L12.2594 10.0479L12.4785 9.81627C12.5989 9.68762 13.3078 8.95 14.0512 8.17807C14.7946 7.40185 15.409 6.74571 15.4176 6.71998C15.4348 6.64707 4.25862 0.214324 3.91057 0.0985346C3.64416 0.00418758 3.10276 -0.0258322 2.80198 0.0299187Z"
											fill="white"
										/>
										<path
											d="M1.50427 0.973423C1.20349 1.44945 1.22497 0.596035 1.21208 10.8498C1.20349 17.5956 1.21208 20.1602 1.24646 20.3489C1.29802 20.6147 1.53005 21.1551 1.58591 21.1337C1.64177 21.1122 11.3656 10.9699 11.3656 10.9356C11.3656 10.9185 9.17419 8.62411 6.49724 5.83658L1.63318 0.771864L1.50427 0.973423Z"
											fill="white"
										/>
										<path
											d="M14.8586 9.11281C13.9176 10.0949 13.1484 10.914 13.1484 10.9354C13.1484 10.9654 15.3098 13.2426 16.4957 14.4649L16.6504 14.6235L18.2789 13.6844C19.177 13.1697 20.0105 12.6723 20.1309 12.5779C20.3973 12.3678 20.6852 11.9518 20.784 11.6259C20.8828 11.3042 20.8828 10.691 20.784 10.3693C20.6852 10.0391 20.393 9.62315 20.1051 9.40443C19.9332 9.27578 16.616 7.3288 16.573 7.33309C16.5688 7.33309 15.7953 8.13504 14.8586 9.11281Z"
											fill="white"
										/>
										<path d="M11.1676 12.9598C6.96953 17.3126 2.64258 21.8413 2.64258 21.8841C2.64258 22.0257 3.51484 22.0385 3.91016 21.9013C4.25391 21.7855 15.516 15.3099 15.4988 15.2413C15.4945 15.2027 13.6941 13.3071 12.4609 12.0378L12.259 11.8276L11.1676 12.9598Z" fill="white" />
									</svg>
								</AppButtonIcon>
								<AppButtonText>Play Market</AppButtonText>
							</AppButton>
						</FooterAppButtons>
					</FooterInfoBlock>
				</FooterInfoBlocks>
				<FooterSmallLinks>
					<FooterSmallLink>“Josti”, 2024</FooterSmallLink>
					<FooterSmallLink onClick={() => window.open('/privacy-policy', '_blank')}>Політика конфіденційності</FooterSmallLink>
					<FooterSmallLink onClick={() => window.open('/terms-of-use', '_blank')}>Умови користування</FooterSmallLink>
				</FooterSmallLinks>
			</PageContainer>
		</FooterWrapper>
	)
}

export default Footer
