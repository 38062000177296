import React, { Suspense } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import Main from './Main'
import ErrorBoundary from './helper/ErrorBoundary'
import { GlobalStyle } from './style/global.styled'
import { Bounce, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const App = () => {
	return (
		<>
			<GlobalStyle />
			<ErrorBoundary>
				<Suspense fallback={<div>Loading..</div>}>
					<Router>
						<Main />
						<ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" transition={Bounce} />
					</Router>
				</Suspense>
			</ErrorBoundary>
		</>
	)
}

export default App
