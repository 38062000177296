import styled from 'styled-components'
import { theme } from '../../../../style/theme'

export const RentLongTermWrapper = styled.div`
	position: relative;
	width: 100%;
	min-height: 450px;
	margin-top: 50px;
	border-radius: 24px;
	overflow: hidden;
	padding: 25px;

	@media screen and (max-width: ${theme.point1024}) {
		margin-top: 25px;
		border-radius: 20px;
		padding: 15px;
		min-height: 330px;
	}

	@media screen and (max-width: ${theme.point520}) {
		margin-top: 0;
		border-radius: 0;
		padding: 0;
		min-height: auto;
	}
`

export const RentLongTermImg = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	position: absolute;
	left: 0;
	top: 0;
	z-index: -1;

	@media screen and (max-width: ${theme.point520}) {
		position: static;
		height: 370px;
	}
`

export const LongTermBlock = styled.div`
	width: calc(54% - 25px);
	background-color: #ffffff;
	min-height: 400px;
	border-radius: 24px;
	overflow: hidden;
	padding: 25px;

	@media screen and (max-width: ${theme.point1024}) {
		width: calc(50% - 15px);
		min-width: 330px;
		border-radius: 20px;
		padding: 15px 18px;
		min-height: 300px;
	}

	@media screen and (max-width: ${theme.point520}) {
		width: 100%;
		margin-top: -18px;
		position: relative;
		z-index: 1;
		min-height: 270px;
	}
`

export const LongTermHeading = styled.div`
	color: #282828;
	font-size: 35px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;

	> b {
		background: #2db916;
		color: #ffffff;
		padding: 0 4px;
		border-radius: 7px;
		white-space: nowrap;
	}

	@media screen and (max-width: ${theme.point1024}) {
		font-size: 22px;
	}

	@media screen and (max-width: ${theme.point520}) {
		width: 60%;
	}
`

export const AppButtons = styled.div`
	margin-top: 50px;
	gap: 15px;
	display: flex;
	align-items: center;

	@media screen and (max-width: ${theme.point1024}) {
		margin-top: 25px;
		gap: 10px;
	}
`

export const AppButton = styled.button`
	background: #282828;
	border-radius: 12px;
	height: 52px;
	display: flex;
	align-items: center;
	padding: 5px 15px;
	gap: 10px;
	cursor: pointer;

	@media screen and (max-width: ${theme.point1024}) {
		height: 48px;
	}
`

export const AppButtonIcon = styled.div`
	width: 22px;
	height: 22px;

	> svg {
		width: 100%;
		height: 100%;
	}

	@media screen and (max-width: ${theme.point1024}) {
		width: 18px;
		height: 18px;
	}
`

export const AppButtonText = styled.div`
	color: #ffffff;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;

	@media screen and (max-width: ${theme.point1024}) {
		font-size: 15px;
	}
`

export const AppLocation = styled.div`
	margin-top: 50px;
	border-top: 1px solid #cecece;
	padding-top: 25px;

	@media screen and (max-width: ${theme.point1024}) {
		margin-top: 25px;
	}
`

export const AppLocationText = styled.div`
	color: #b4b4b4;
	font-size: 15px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;

	@media screen and (max-width: ${theme.point1024}) {
		font-size: 12px;
	}
`

export const AppLocationItems = styled.div`
	margin-top: 25px;
	display: flex;
	align-items: center;
	gap: 15px;

	@media screen and (max-width: ${theme.point1024}) {
		margin-top: 15px;
		gap: 10px;
	}
`

export const LocationItem = styled.div`
	height: 48px;
	padding: 5px 15px;
	display: flex;
	align-items: center;
	gap: 10px;
	background-color: #f5f4f9;
	border-radius: 30px;

	@media screen and (max-width: ${theme.point1024}) {
		height: 34px;
		padding: 5px 10px;
		gap: 5px;
	}
`

export const LocationItemIcon = styled.div`
	width: 15px;
	height: 18px;
	display: flex;
	align-items: center;
	justify-content: center;

	@media screen and (max-width: ${theme.point1024}) {
		width: 9px;
		height: 10px;
	}
`

export const LocationItemText = styled.div`
	color: #282828;
	text-align: right;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;

	@media screen and (max-width: ${theme.point1024}) {
		font-size: 12px;
	}
`
