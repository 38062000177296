import React from 'react'
import earning from '../../../../img/earning/earning.webp'
import money from '../../../../img/earning/money.png'
import time from '../../../../img/earning/time.png'
import thumbs from '../../../../img/earning/thumbs.png'
import { EarningButton, EarningItem, EarningItemIcon, EarningItemInfo, EarningItemText, EarningItemTitle, EarningList, StartEarningImage, StartEarningInfo, StartEarningWrapper } from './index.styled'
import { hostUiUrl } from '../../../../urls'

const StartEarning = () => {
	return (
		<StartEarningWrapper>
			<StartEarningImage>
				<img src={earning} alt="Earning" />
			</StartEarningImage>
			<StartEarningInfo>
				<EarningList>
					<EarningItem>
						<EarningItemIcon src={money} alt="Money" />
						<EarningItemInfo>
							<EarningItemTitle>Здавайте житло на вигідних умовах</EarningItemTitle>
							<EarningItemText>Розміщуйте безкоштовно оголошення вже зараз та піднімайте свій об’єкт в топ за мінімальну плату.</EarningItemText>
						</EarningItemInfo>
					</EarningItem>
					<EarningItem>
						<EarningItemIcon src={time} alt="Time" />
						<EarningItemInfo>
							<EarningItemTitle>Економте свій час</EarningItemTitle>
							<EarningItemText>Вас не будуть щодня наполегливо турбувати рієлтори, натомість очікуйте дзвінки лише від потенційних орендарів, які оплатили перегляд.</EarningItemText>
						</EarningItemInfo>
					</EarningItem>
					<EarningItem>
						<EarningItemIcon src={thumbs} alt="Thumbs" />
						<EarningItemInfo>
							<EarningItemTitle>Додатковий сервіс за рахунок Josti (За бажанням)</EarningItemTitle>
							<EarningItemText>Ми піклуємось про власників квартир, які співпрацюють з нами, тому надаємо безкоштовний кліннінг та обслуговування.</EarningItemText>
						</EarningItemInfo>
					</EarningItem>
				</EarningList>
				<EarningButton onClick={() => window.open(`${hostUiUrl}`, '_blank')}>Дізнатися більше</EarningButton>
			</StartEarningInfo>
		</StartEarningWrapper>
	)
}

export default StartEarning
