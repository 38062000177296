import styled from 'styled-components'
import { theme } from '../../../../style/theme'

export const JostiDefinitionsWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 15px;

	@media screen and (max-width: ${theme.point1024}) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media screen and (max-width: ${theme.point520}) {
		grid-template-columns: 1fr;
	}
`

export const DefinitionBlock = styled.div`
	background-color: #ffffff;
	padding: 27px 25px;
	border-radius: 24px;

	@media screen and (max-width: ${theme.point1280}) {
		padding: 15px;
		border-radius: 20px;
	}

	@media screen and (max-width: ${theme.point520}) {
		height: 240px;
	}
`

export const DefinitionBlockIcon = styled.img`
	width: 38px;
	height: 38px;
`

export const DefinitionBlockTitle = styled.div`
	margin-top: 25px;
	color: #282828;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`

export const DefinitionList = styled.div``

export const DefinitionListItem = styled.div`
	display: flex;
	margin-top: 15px;
	gap: 10px;
	padding-right: 25px;
`

export const DefinitionListArrow = styled.div`
	flex-shrink: 0;
	width: 21px;
	height: 21px;

	> svg {
		width: 100%;
		height: 100%;
	}
`

export const DefinitionListText = styled.div`
	color: #282828;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
`
