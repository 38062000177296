import React, { useState } from 'react'
import arrow from '../../../../img/header/arrow.png'
import { NavLinkArrow, NavLinkButton, NavLinkItem, NavLinkItemLink, NavLinkItems, NavLinkItemsWrapper, NavLinkText, NavLinkWrapper } from '../../index.styled'

const HeaderNavLink = ({ text, subItems = [] }) => {
	const [isOpen, setIsOpen] = useState(false)

	const layout = isOpen ? 'active' : ''

	const handleMouseEnter = () => {
		setIsOpen(true)
	}

	const handleMouseLeave = () => {
		setIsOpen(false)
	}

	return (
		<NavLinkWrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={layout}>
			<NavLinkButton className={layout}>
				<NavLinkText>{text}</NavLinkText>
				<NavLinkArrow>
					<img src={arrow} alt="Arrow" />
				</NavLinkArrow>
			</NavLinkButton>
			<NavLinkItemsWrapper className={layout}>
				<NavLinkItems>
					{subItems.map(subItem => (
						<NavLinkItem key={subItem.text}>
							<NavLinkItemLink key={subItem.text} to={`/#${subItem.elementId}`} scroll={el => el.scrollIntoView({ behavior: 'auto', block: 'start' })}>
								{subItem.text}
							</NavLinkItemLink>
						</NavLinkItem>
					))}
				</NavLinkItems>
			</NavLinkItemsWrapper>
		</NavLinkWrapper>
	)
}

export default HeaderNavLink
