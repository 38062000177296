import styled from 'styled-components'
import { HashLink } from 'react-router-hash-link'
import { theme } from '../../style/theme'

export const FooterWrapper = styled.footer`
	width: 100%;
	background: #ffffff;
	padding: 120px 0 40px;
	margin-top: 120px;

	@media screen and (max-width: ${theme.point1024}) {
		margin-top: 80px;
		padding: 80px 0;
	}
`

export const FooterLogo = styled.div`
	width: 90px;
	height: 40px;

	> svg {
		width: 100%;
		height: 100%;
	}

	@media screen and (max-width: ${theme.point1024}) {
		width: 86px;
		height: 35px;
	}
`

export const FooterInfoBlocks = styled.div`
	margin-top: 50px;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	@media screen and (max-width: ${theme.point1024}) {
		display: grid;
		grid-template-columns: repeat(3, auto);
	}

	@media screen and (max-width: ${theme.point720}) {
		margin-top: 25px;
		grid-template-columns: 1fr;
	}
`

export const FooterInfoBlock = styled.div`
	@media screen and (max-width: ${theme.point1024}) {
		&.host {
			grid-row: 2;
			grid-column: 1;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		border-bottom: 1px solid #cecece80;
		padding: 25px 0;

		&.download {
			grid-row: 1;
		}

		&.host {
			grid-row: 3;
		}
	}
`

export const FooterInfoBlockTitle = styled.div`
	color: #282828;
	font-size: 22px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;

	@media screen and (max-width: ${theme.point1024}) {
		font-size: 18px;
	}
`

export const FooterInfoBlockLink = styled(HashLink)`
	text-decoration: none;
	margin-top: 25px;
	color: #282828;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	display: flex;
	align-items: center;
	gap: 10px;

	> img {
		width: 18px;
		height: 18px;
	}

	&:hover {
		opacity: 0.5;
	}

	@media screen and (max-width: ${theme.point1024}) {
		margin-top: 20px;
		font-size: 15px;

		> img {
			width: 13px;
			height: 13px;
		}
	}
`

export const FooterSocialMedia = styled.div`
	margin-top: 50px;
	display: flex;
	align-items: center;
	gap: 15px;

	@media screen and (max-width: ${theme.point1024}) {
		gap: 10px;
	}
`

export const SocialMediaLink = styled.div`
	width: 48px;
	height: 48px;
	border-radius: 24px;
	background-color: #f5f4f9;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	&:hover {
		opacity: 0.5;
	}

	@media screen and (max-width: ${theme.point1024}) {
		width: 38px;
		height: 38px;
	}
`

export const SocialMediaIcon = styled.img`
	width: 24px;
	height: 24px;

	@media screen and (max-width: ${theme.point1024}) {
		width: 20px;
		height: 20px;
	}
`

export const FooterAppButtons = styled.div`
	display: flex;
	flex-direction: column;
	gap: 15px;
	margin-top: 25px;
	width: fit-content;

	@media screen and (max-width: ${theme.point1024}) {
		gap: 10px;
	}

	@media screen and (max-width: ${theme.point720}) {
		flex-direction: row;
	}
`

export const AppButton = styled.button`
	background: #282828;
	border-radius: 12px;
	height: 52px;
	display: flex;
	align-items: center;
	padding: 5px 15px;
	gap: 10px;
	cursor: pointer;

	@media screen and (max-width: ${theme.point1024}) {
		height: 48px;
	}
`

export const AppButtonIcon = styled.div`
	width: 22px;
	height: 22px;

	> svg {
		width: 100%;
		height: 100%;
	}

	@media screen and (max-width: ${theme.point1024}) {
		width: 18px;
		height: 18px;
	}
`

export const AppButtonText = styled.div`
	color: #ffffff;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;

	@media screen and (max-width: ${theme.point1024}) {
		font-size: 15px;
	}
`

export const FooterSmallLinks = styled.div`
	margin-top: 50px;
	display: flex;
	gap: 50px;
	align-items: center;

	@media screen and (max-width: ${theme.point720}) {
		margin-top: 25px;
		flex-direction: column;
		gap: 20px;
		align-items: flex-start;
	}
`

export const FooterSmallLink = styled.div`
	color: #b4b4b4;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	cursor: pointer;

	@media screen and (max-width: ${theme.point1024}) {
		font-size: 12px;
	}
`
