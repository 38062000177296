import styled from 'styled-components'
import { theme } from '../../../../style/theme'

export const ActualWrapper = styled.div``

export const FlatList = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 15px;

	@media screen and (max-width: ${theme.point1024}) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media screen and (max-width: ${theme.point720}) {
		grid-template-columns: 1fr;
	}
`

export const FlatWrapper = styled.div`
	border-radius: 12px;
	background: #fff;
	overflow: hidden;

	@media screen and (max-width: ${theme.point720}) {
		width: 350px;
		margin: 0 auto;
	}

	@media screen and (max-width: ${theme.point520}) {
		width: 100%;
	}
`

export const FlatImage = styled.img`
	width: 100%;
	height: 155px;
	object-fit: cover;
`

export const FlatInfo = styled.div`
	padding: 30px 15px 15px;
`

export const FlatCity = styled.div`
	color: #282828;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`

export const FlatStreet = styled.div`
	color: #282828;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-top: 10px;
	display: flex;
	align-items: center;
	gap: 2px;

	> img {
		width: 13px;
		height: 13px;
	}
`

export const FlatBlocks = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	margin-top: 15px;
	width: 80%;
`

export const FlatBlock = styled.div`
	height: 27px;
	border-radius: 7px;
	border: 1px solid #cecece;
	padding: 1px 5px;
	display: flex;
	align-items: center;
	color: #b4b4b4;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`

export const FlatMetroPoint = styled.div`
	width: 17px;
	height: 17px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: #fff;
	font-size: 8px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	border-radius: 50%;
	margin-right: 3px;
`

export const FlatFooter = styled.div`
	border-top: 1px solid #cecece80;
	padding-top: 15px;
	margin-top: 15px;
`

export const FlatPrice = styled.div`
	color: #724fff;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;

	> span {
		color: #282828;
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
`

export const MoreFlatsButton = styled.button`
	margin-top: 50px;
	margin-right: auto;
	margin-left: auto;
	border-radius: 30px;
	background: #724fff;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 51px;
	padding: 5px 30px;
	color: #fff;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	cursor: pointer;

	@media screen and (max-width: ${theme.point1024}) {
		width: 100%;
		min-height: 48px;
		font-size: 15px;
	}
`
