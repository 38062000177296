import React, { useState } from 'react'
import arrow from '../../../../../img/header/arrow.png'
import { NavLinkArrow, NavLinkButton, NavLinkItem, NavLinkItems, NavLinkItemsWrapper, NavLinkText, NavLinkWrapper } from './index.styled'

const MobileHeaderNavLink = ({ text, subItems = [], onClick }) => {
	const [isOpen, setIsOpen] = useState(false)

	const layout = isOpen ? 'active' : ''

	const handleClick = () => {
		setIsOpen(!isOpen)
	}

	const handleScroll = el => {
		onClick()
		el.scrollIntoView({ behavior: 'auto', block: 'start' })
	}

	return (
		<NavLinkWrapper onClick={handleClick} className={layout}>
			<NavLinkButton className={layout}>
				<NavLinkText>{text}</NavLinkText>
				<NavLinkArrow className={layout}>
					<img src={arrow} alt="Arrow" />
				</NavLinkArrow>
			</NavLinkButton>
			{isOpen && (
				<NavLinkItemsWrapper>
					<NavLinkItems>
						{subItems.map(subItem => (
							<NavLinkItem key={subItem.text} to={`#${subItem.elementId}`} scroll={handleScroll}>
								{subItem.text}
							</NavLinkItem>
						))}
					</NavLinkItems>
				</NavLinkItemsWrapper>
			)}
		</NavLinkWrapper>
	)
}

export default MobileHeaderNavLink
